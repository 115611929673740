@use 'sass:math';
@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v.$white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: opacity 0.6s ease 1s;
  pointer-events: none;

  &.is-hide {
    opacity: 0;
  }

  &_cont {
    width: 50vw;
    height: 50vh;
    position: relative;

    @include m.sp {
      width: 100vw;
      height: 70vh;
    }
  }

  &_item {
    display: flex;
    justify-content: space-around;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  &_dots {
    border-radius: 50%;
    background: v.$greenLight;
  }

  $i: 1;
  @while $i <= v.$rows {
    // $scale: $i / v.$rows;
    $scale: math.div($i, v.$rows);

    .c-loading {
      &_item--#{$i} {
        // Small (ugly) tweak to make all rows vertically centered
        top: 200px + $i;
        // width: v.$dot-size * 1.93 * $i * (1 - $scale * .3);
        width: v.$dot-size * 1.5 * $i * (1 - $scale * 0.5);
        animation-delay: 120ms * $i - (v.$rows * 180ms);
        animation-name: wave-#{$i};
      }

      &_item--#{$i} {
        .c-loading_dots {
          background: v.$greenLight;
          // height: v.$dot-size * (1 - $scale * 0.7);
          // width: v.$dot-size * (1 - $scale * 0.7);
          width: 4px;
          height: 4px;
        }
      }
    }

    @keyframes wave-#{$i} {
      // $scale: ($i) / v.$rows;
      $scale: math.div($i, v.$rows);
      // $wave-height: 130px;
      $wave-height: 60px;
      $max-offset: 30;
      from {
        transform: translateY($wave-height - $max-offset * $scale);
      }
      to {
        transform: translateY(-1 * $wave-height + $max-offset * $scale);
      }
    }

    $i: $i + 1;
  }
}
