@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  font-family: v.$tomorrow, v.$zenKaku;

  &.is-fixed {
    overflow: hidden;
  }
}

main {
  position: relative;
  z-index: 2;

  &.is-stayed {
    position: static !important;
  }
}

.dataScroll {
  will-change: transform;

  &.is-stayed {
    transform: none !important;
  }
}

img {
  display: block;
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}

@keyframes anim {
  0% {
    transform: translateZ(0);
  }
  50% {
    transform: translateZ(calc(v.$cube-s/2));
  }
  100% {
    transform: translateZ(0);
  }
}

@keyframes move {
  0% {
    transform: scale(1) rotateZ(0deg);
  }
  25% {
    transform: scale(1.1) rotateZ(10deg);
  }
  50% {
    transform: scale(1) rotateZ(0deg);
  }
  75% {
    transform: scale(1.1) rotateZ(-12deg);
  }
  100% {
    transform: scale(1) rotateZ(0deg);
  }
}
