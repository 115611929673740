@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-color {
  &_text {
    &--green {
      color: v.$green;
    }

    &--white {
      color: v.$white;
    }

    &--grayPale {
      color: v.$grayPale;
    }
  }

  &_bg {
    &--green {
      background-color: v.$green;
    }

    &--white {
      background-color: v.$white;

      &--sp {
        @include m.sp {
          background-color: v.$white;
        }
      }
    }

    &--grayPale {
      background-color: v.$grayPale;
    }
  }
}
