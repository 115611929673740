@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-intro {
  width: 100%;
  margin: 0 0 56px;

  @include m.sp {
    margin: 0 0 3rem;
  }

  &_heading {
    width: 100%;
    padding: 0 0 20px;
    font-size: 80px;
    font-weight: 300;
    color: v.$green;

    @include m.sp {
      padding: 0 0 2rem;
      font-size: 4rem;
    }

    &--large {
      @extend .c-intro_heading;
      font-size: 123px;
      white-space: nowrap;

      @include m.sp {
        font-size: 6rem;
      }

      &--gray {
        @extend .c-intro_heading--large;
        color: v.$gray;
      }
    }
  }

  &_text {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    font-family: v.$zenKaku;
    letter-spacing: 0.2em;
    color: v.$green;

    @include m.tab {
      font-size: 20px;
    }

    @include m.sp {
      font-size: 2rem;
    }
  }

  &_loop {
    display: inline-flex;
    position: relative;
    overflow: hidden;

    &--company {
      @extend .c-intro_loop;
      margin: 0 0 28px;

      @include m.sp {
        margin: 0 0 1.5rem;
      }
    }

    &Cont {
      display: flex;
      flex-shrink: 0;
      will-change: transform;

      &:first-child {
        animation: slide1 120s -60s linear infinite;
      }

      &:last-child {
        animation: slide2 120s linear infinite;
      }

      @include m.pc {
        // &:hover {
        //   animation-play-state: paused;
        // }
      }

      // & + & {
      //   margin: 0 0 0 60px;

      //   @include m.sp {
      //     margin: 0 0 0 3rem;
      //   }
      // }
    }

    &Box {
      margin: 0 30px;

      @include m.sp {
        margin: 0 1.5rem;
      }

      &--service {
        @extend .c-intro_loopBox;
        width: 100%;

        @include m.pc {
          max-width: 1400px;
        }

        @include m.sp {
          width: 60rem;
        }
      }

      &--company {
        @extend .c-intro_loopBox;
        width: 100%;

        @include m.pc {
          max-width: 1170px;
        }

        @include m.sp {
          width: 60rem;
        }
      }
    }
  }
}

@keyframes slide1 {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slide2 {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-200%);
  }
}
