@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-card {
  width: 100%;

  &_pic {
    width: 100%;
    padding: 0 0 12px;
  }

  &_box {
    width: 100%;
  }

  &_text {
    font-family: v.$zenKaku;
    line-height: 2;

    &--color {
      @extend .c-card_text;
      color: v.$green;
    }
  }

  &Wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include m.pc {
      &:after {
        content: '';
        width: 32%;
        display: block;
      }
    }

    &_list {
      width: 32%;

      @include m.pc {
        max-width: 280px;
      }

      @include m.sp {
        width: 47%;
      }

      &:nth-child(n + 4) {
        @include m.pc {
          margin-top: 60px;
        }

        @include m.tab {
          margin-top: 40px;
        }
      }

      &:nth-child(n + 3) {
        @include m.sp {
          margin-top: 2rem;
        }
      }
    }

    &_item {
      width: 100%;
      display: block;

      @include m.pc {
        transition: opacity v.$anime;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
