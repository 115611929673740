@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-btn {
  $this: &;
  width: 500px;
  margin: 60px auto 0;
  padding: 40px;
  display: block;
  color: v.$green;
  background-color: v.$white;
  border: 1px solid v.$green;
  position: relative;
  z-index: 1;

  @include m.pc {
    transition: color v.$anime, background-color v.$anime;

    .c-btn {
      &_logo {
        &--wantedly {
          svg * {
            transition: fill v.$anime;
          }
        }
      }
    }

    &:hover {
      color: v.$white;
      background-color: v.$green;

      .c-btn {
        &_logo {
          &--wantedly {
            svg * {
              fill: v.$white;
            }
          }
        }
      }
    }
  }

  @include m.tab {
    width: 420px;
    padding: 32px 28px;
  }

  @include m.sp {
    width: 100%;
    margin: 40px auto 0;
    padding: 2rem 1.2rem;
  }

  &--inner {
    @extend #{$this};
    width: 100%;
    margin: 0;

    @include m.pc {
      padding: 40px 32px;
    }

    .c-btn_text {
      margin-right: 12px;

      @include m.sp {
        margin-right: 0.5rem;
      }
    }
  }

  &--reverse {
    color: v.$white;
    background-color: v.$green;

    @include m.pc {
      &:hover {
        color: v.$green;
        background-color: v.$white;
      }
    }

    &[target='_blank'] {
      .c-btn {
        &_ico {
          &Inner {
            background: url('../img/common/ico_external_white.png') no-repeat center !important;
            background-size: contain !important;
          }
        }
      }

      @include m.pc {
        &:hover {
          .c-btn {
            &_ico {
              &Inner {
                background-image: url('../img/common/ico_external.png') !important;
                background-size: contain !important;
              }
            }
          }
        }
      }
    }
  }

  &[target='_blank'] {
    .c-btn {
      &_ico {
        &Inner {
          width: 20px;
          height: 18px;
          margin-left: 12px;
          background: url('../img/common/ico_external.png') no-repeat center;
          background-size: contain;

          @include m.sp {
            width: 16px;
            height: 14px;
          }
        }
      }
    }

    @include m.pc {
      &:hover {
        .c-btn {
          &_ico {
            &Inner {
              background-image: url('../img/common/ico_external_white.png');
            }
          }
        }
      }
    }
  }

  &_cont {
    $this: &;
    display: flex;
    position: relative;
    z-index: 1;

    &--between {
      @extend #{$this};
      justify-content: space-between;
      align-items: center;
    }

    &--center {
      @extend #{$this};
      justify-content: center;
      align-items: center;
    }
  }

  &_logo {
    &--wantedly {
      width: 102px;
      position: absolute;
      right: 12px;
      bottom: 12px;

      @include m.tab {
        width: 80px;
      }
      @include m.sp {
        width: 7rem;
        right: 0.5rem;
        bottom: 0.5rem;
      }
    }
  }

  &_inner {
    $this: &;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    &--center {
      @extend #{$this};
      justify-content: center;
    }
  }

  &_text {
    font-family: v.$zenKaku;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4444444444444444;
    letter-spacing: 0.1em;

    @include m.tab {
      font-size: 16px;
    }

    @include m.sp {
      font-size: 1.3rem;
    }
  }

  &_ico {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 2;
    transform: translateY(-50%);
  }
}
