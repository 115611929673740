@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-breadcrumbs {
  width: 100%;
  padding: 60px 0;
  position: relative;
  z-index: 1;

  @include m.tab {
    padding: 40px 0;
  }

  @include m.sp {
    padding: 3rem 0;
  }

  &--long {
    @extend .c-breadcrumbs;
    padding: 100px 0 40px;

    @include m.sp {
      padding: 8rem 0 2rem;
    }
  }

  &Cont {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &_list {
      display: flex;
      align-items: center;

      & + & {
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          margin: 2px 12px 0 10px;
          display: block;
          border-right: 1px solid v.$white;
          border-bottom: 1px solid v.$white;
          transform-origin: center;
          transform: rotate(-45deg);

          @include m.tab {
            width: 8px;
            height: 8px;
          }

          @include m.sp {
            width: 0.8rem;
            height: 0.8rem;
          }
        }
      }
    }

    &_item {
      display: flex;

      @include m.pc {
        transition: color v.$anime;

        &:hover {
          color: v.$greenLight;
        }
      }
    }
  }
}
