@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-detail {
  width: 100%;

  &Intro {
    width: 100%;
    margin: 0 0 60px;

    @include m.tab {
      margin: 0 0 40px;
    }

    @include m.sp {
      margin: 0 0 2rem;
    }

    &_heading {
      width: 100%;
      margin: 0 0 20px;

      @include m.tab {
        margin: 0 0 12px;
      }

      @include m.sp {
        margin: 0 0 0.5rem;
      }

      &--spacing {
        @extend .c-detailIntro_heading;
        letter-spacing: 0.2em;
      }

      &--inner {
        @extend .c-detailIntro;
        margin: 0;
      }
    }

    &--inner {
      @extend .c-detailIntro;
      margin: 0;
    }

    &_lead {
      &--spacing {
        @extend .c-detailIntro_lead;
        letter-spacing: 0.2em;
      }
    }
  }

  &Cont {
    width: 100%;

    &_inner {
      width: 100%;
      margin-top: 16px;

      &--long {
        width: 100%;
        margin-top: 2em;
      }
    }

    &_box {
      width: 100%;

      & + & {
        margin-top: 72px;

        @include m.sp {
          margin-top: 3.5rem;
        }
      }
    }

    &_text {
      display: inline-block;
      font-family: v.$zenKaku;
    }
  }

  &Btn {
    width: 100%;
    margin: 60px 0 0;
    display: flex;
    justify-content: flex-end;

    @include m.tab {
      margin: 40px 0 0;
    }

    @include m.sp {
      margin: 2rem 0 0;
    }
  }
}
