@charset "UTF-8";

/* transition */
$anime: 0.3s ease;
$animeBezier: cubic-bezier(0.26, 0.06, 0, 1);

/* color */
// white
$white: #fff;

// black
$black: #000;
$blackPale: #313131;

// gray
$gray: #e0e0e0;
$grayPale: #f4f4f4;

// green
$green: #004155;
$greenLight: #00b7a9;

/* font-family */
$tomorrow: 'Tomorrow';
$zenKaku: 'Zen Kaku Gothic New';

/* MV dots Animation */
$line-cube-n: 42;
$cube-n: pow($line-cube-n, 2);
// $cube-s: 0.8em;
$cube-s: 0.4em;
$cube-n-root: sqrt($cube-n);

/* loading Animation */
$rows: 13;
$dot-size: 30px;
