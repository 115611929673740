@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-ico {
  &Box {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      flex-wrap: wrap;
    }

    &--list03 {
      @extend .c-icoBox;

      @include m.sp {
        display: block;
      }

      .c-icoBox {
        &_list {
          width: 32%;

          @include m.pc {
            max-width: 315px;
          }

          @include m.sp {
            width: 100%;

            &:nth-child(n + 3) {
              margin-top: 0;
            }

            & + .c-icoBox_list {
              margin-top: 2rem;
            }
          }
        }

        &_item {
          padding: 80px 0;

          @include m.tab {
            padding: 60px 0;
          }

          @include m.sp {
            height: 12rem;
            padding: 0;
          }
        }
      }
    }

    &--list04 {
      @extend .c-icoBox;

      @include m.sp {
        display: block;
      }

      .c-icoBox {
        &_list {
          width: 23%;

          @include m.pc {
            max-width: 229px;
          }

          @include m.sp {
            width: 100%;

            &:nth-child(n + 3) {
              margin-top: 0;
            }

            & + .c-icoBox_list {
              margin-top: 2rem;
            }
          }
        }

        &_item {
          padding: 80px 0;

          @include m.tab {
            padding: 60px 0;
          }

          @include m.sp {
            height: 12rem;
            padding: 0;
          }
        }
      }
    }

    &_list {
      width: 23%;

      @include m.pc {
        max-width: 235px;
      }

      @include m.sp {
        width: 48%;

        &:nth-child(n + 3) {
          margin: 1.5rem 0 0;
        }
      }
    }

    &_item {
      width: 100%;
      height: 100%;
      padding: 48px 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      color: v.$green;
      background-color: v.$white;
      border: 1px solid v.$green;

      @include m.pc {
        transition: color v.$anime, background-color v.$anime;

        .c-icoBox {
          &_arrow {
            transition: border-color v.$anime;
          }
        }

        &:hover {
          color: v.$white;
          background-color: v.$green;

          .c-icoBox {
            &_ico {
              &--company {
                background: url('../img/common/ico_company_white.svg') no-repeat center;
                background-size: 100%;
              }

              &--about {
                background: url('../img/common/ico_about_white.svg') no-repeat center;
                background-size: 100%;
              }

              &--work {
                background: url('../img/common/ico_work_white.svg') no-repeat center;
                background-size: 100%;
              }

              &--rule {
                background: url('../img/common/ico_rule_white.svg') no-repeat center;
                background-size: 100%;
              }
            }

            &_arrow {
              border-color: v.$white;
            }
          }
        }
      }

      @include m.sp {
        padding: 3rem 0;
      }

      &.is-active {
        color: v.$white;
        background-color: v.$green;
        position: relative;
        z-index: 1;

        .c-icoBox {
          &_ico {
            &--company {
              background: url('../img/common/ico_company_white.svg') no-repeat center;
              background-size: 100%;
            }

            &--about {
              background: url('../img/common/ico_about_white.svg') no-repeat center;
              background-size: 100%;
            }

            &--work {
              background: url('../img/common/ico_work_white.svg') no-repeat center;
              background-size: 100%;
            }

            &--rule {
              background: url('../img/common/ico_rule_white.svg') no-repeat center;
              background-size: 100%;
            }
          }

          &_arrow {
            border-color: v.$white;
          }
        }

        @include m.pc {
          &:after {
            content: '';
            @include m.opacity();
          }

          &:hover {
            &:after {
              opacity: 0.3;
            }
          }
        }

        @include m.sp {
          padding: 3rem 0;
        }
      }
    }

    &_ico {
      margin: 0 0 20px;

      @include m.sp {
        margin: 0 0 1.2rem;
      }

      &--company {
        @extend .c-icoBox_ico;
        width: 56px;
        height: 56px;
        background: url('../img/common/ico_company.svg') no-repeat center;
        background-size: 100%;

        @include m.sp {
          width: 3.5rem;
          height: 3.5rem;
        }
      }

      &--about {
        @extend .c-icoBox_ico;
        width: 58px;
        height: 58px;
        background: url('../img/common/ico_about.svg') no-repeat center;
        background-size: 100%;

        @include m.sp {
          width: 3.6rem;
          height: 3.6rem;
        }
      }

      &--work {
        @extend .c-icoBox_ico;
        width: 53px;
        height: 56px;
        background: url('../img/common/ico_work.svg') no-repeat center;
        background-size: 100%;

        @include m.sp {
          width: 3.3rem;
          height: 3.5rem;
        }
      }

      &--rule {
        @extend .c-icoBox_ico;
        width: 51px;
        height: 60px;
        background: url('../img/common/ico_rule.svg') no-repeat center;
        background-size: 100%;

        @include m.sp {
          width: 3.2rem;
          height: 3.8rem;
        }
      }
    }

    &_text {
      padding: 0 0 28px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        padding: 0 0 1.4rem;
        font-size: 1.6rem;
      }
    }

    &_arrow {
      width: 10px;
      height: 10px;
      margin: 0 auto;
      display: block;
      border-right: 2px solid v.$green;
      border-bottom: 2px solid v.$green;
      transform: rotate(-45deg);

      @include m.sp {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}
