@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-info {
  width: 100%;
  padding: 220px 0 160px;

  @include m.tab {
    padding: 180px 0 120px;
  }

  @include m.sp {
    padding: 6rem 0 4rem;
  }

  &Other {
    width: 100%;
  }

  &Archive {
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: space-between;

    @include m.tab {
      padding: 40px 0;
    }

    @include m.sp {
      padding: 3rem 0;
      display: block;
    }

    &_intro {
      width: 240px;
      margin: 0 240px 0 0;

      @include m.tab {
        margin: 0 0 160px 0 0;
      }

      @include m.sp {
        width: 100%;
        margin: 0 0 1rem;
      }
    }

    &_heading {
      font-size: 21px;
      font-weight: 500;
      line-height: 2;
      letter-spacing: 0.2em;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 2rem;
        line-height: 1;
      }
    }

    &_cont {
      flex: 1;
    }

    &_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 8px 0;

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:after {
          content: '/';
          margin: 0 16px;
          color: v.$gray;
        }
      }

      a {
        font-family: v.$zenKaku;
        font-size: 18px;
        font-weight: 500;
        color: v.$gray;

        @include m.pc {
          transition: color v.$anime;

          &:hover {
            color: v.$greenLight;
          }
        }

        @include m.tab {
          font-size: 16px;
        }

        @include m.sp {
          font-size: 1.4rem;
        }

        &.is-selected {
          color: v.$green;

          @include m.pc {
            &:hover {
              color: v.$greenLight;
            }
          }
        }
      }
    }
  }

  &Detail {
    width: 100%;

    &_intro {
      width: 100%;
      margin: 0 0 20px;
    }

    &_heading {
      width: 100%;
      margin: 0 0 12px;
    }

    &_main {
      width: 100%;
      margin: 0 auto 20px;

      img {
        margin: 0 auto;
      }
    }

    &_box {
      & * {
        font-family: v.$zenKaku;
      }

      h2,
      h3,
      h4,
      h5 {
        font-weight: 500;
        line-height: 2;
        color: v.$green;
      }

      h2 {
        width: 100%;
        margin-bottom: 24px;
        font-size: 24px;
        border-bottom: 1px solid v.$gray;

        @include m.tab {
          font-size: 18px;
        }

        @include m.sp {
          font-size: 1.6rem;
        }
      }

      h3 {
        margin-bottom: 20px;
        padding: 0 8px;
        display: table;
        font-size: 20px;
        background-color: v.$grayPale;
      }

      h4 {
        margin-bottom: 18px;
        font-size: 18px;
      }

      h5 {
        margin-bottom: 16px;
        font-size: 16px;
      }

      p,
      a {
        font-size: 16px;
        font-weight: 500;
        line-height: 2;

        @include m.tab {
          font-size: 14px;
        }

        @include m.sp {
          font-size: 1.4rem;
        }

        & + & {
          margin-top: 32px;

          @include m.tab {
            margin-top: 24px;
          }

          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }

      a {
        color: v.$green;
        text-decoration: underline;

        @include m.pc {
          transition: color v.$anime;

          &:hover {
            color: v.$greenLight;
          }
        }
      }
    }

    &_btn {
      width: 100%;
      margin: 160px 0 0;
      display: flex;
      justify-content: center;

      @include m.tab {
        margin: 120px 0 0;
      }

      @include m.sp {
        margin: 6rem 0 0;
      }
    }
  }

  &Nav {
    width: 100%;
    overflow: hidden;

    &_cont {
      width: 100%;
      display: flex;
      position: relative;
      z-index: 1;

      @include m.sp {
        &:after {
          content: '';
          width: 1px;
          height: 65%;
          background-color: v.$green;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%, -50%);
        }
      }
    }

    &_list {
      width: 50%;

      &:first-child {
        .p-infoNav_item {
          &:before {
            right: 0;
          }

          &:after {
            display: none;
          }
        }
      }

      &:last-child {
        .p-infoNav_item {
          &:before {
            display: none;
          }

          &:after {
            left: 0;
          }
        }
      }
    }

    &_item {
      width: 100%;
      padding: 48px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      color: v.$green;
      position: relative;
      z-index: 1;

      &:before,
      &:after {
        content: '';
        width: 200vw;
        height: 100%;
        background-color: v.$grayPale;
        position: absolute;
        top: 0;
        z-index: -1;
      }

      @include m.pc {
        transition: color v.$anime;

        &:before,
        &:after {
          transition: background-color v.$anime;
        }

        .p-infoNav_arrow {
          transition: border-color v.$anime;
        }

        &:hover {
          color: v.$white;

          &:before,
          &:after {
            background-color: v.$green;
          }

          .p-infoNav_arrow {
            border-color: v.$white;
          }
        }
      }

      @include m.tab {
        padding: 28px 0;
        font-size: 16px;
      }

      @include m.sp {
        padding: 3rem 0;
        font-size: 1.6rem;
      }
    }

    &_arrow {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      z-index: 2;
      transform: translateY(-50%) rotate(-45deg);

      @include m.tab {
        width: 16px;
        height: 16px;
      }

      @include m.sp {
        width: 1.5rem;
        height: 1.5rem;
      }

      &--back {
        @extend .p-infoNav_arrow;
        border-top: 1px solid v.$green;
        border-left: 1px solid v.$green;
        left: 0;
      }

      &--next {
        @extend .p-infoNav_arrow;
        border-right: 1px solid v.$green;
        border-bottom: 1px solid v.$green;
        right: 0;
      }
    }

    &_text {
      position: relative;
      z-index: 2;
    }
  }
}
