@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-section {
  // scroll-snap-type: y mandatory;
  // overflow: auto;
  // height: 100vh;
  // overflow: hidden;

  &Cont {
    width: 100%;
    padding: 180px 0;
    background-color: v.$white;
    overflow: hidden;

    @include m.sp {
      padding: 6rem 0;
    }

    &--gray {
      @extend .l-sectionCont;
      background-color: v.$grayPale;
    }
  }
}
