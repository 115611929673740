@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-font {
  &--zenkaku {
    font-family: v.$zenKaku;
  }

  &--tomorrow {
    font-family: v.$tomorrow;
  }
}
