@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  width: 100%;
  height: 90px;
  background-color: rgba(v.$white, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color v.$anime;

  @include m.sp {
    height: 7rem;
  }

  &.is-animated {
    background-color: rgba(v.$white, 0.5);

    .l-header {
      &_logo {
        svg {
          path {
            fill: v.$green;
          }
        }
      }

      &Hamburger {
        &.is-active {
          .l-headerHamburger_line {
            background-color: v.$white;
          }
        }

        &_line {
          background-color: v.$green;
        }
      }
    }
  }

  &_wrap {
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;

    @include m.sp {
      padding: 2rem 1.5rem;
    }
  }

  &_logo {
    width: 30%;

    @include m.pc {
      max-width: 180px;
    }

    svg {
      path {
        transition: fill v.$anime;
      }
    }
  }

  &_cont {
    width: 100vw;
    height: 0;
    background-color: v.$green;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    @keyframes menuOpen {
      0% {
        height: 0;
        opacity: 0;
        pointer-events: none;
      }
      100% {
        height: 100vh;
        opacity: 1;
        pointer-events: auto;
      }
    }
    @keyframes menuClose {
      0% {
        height: 100vh;
        opacity: 1;
        pointer-events: auto;
      }
      100% {
        height: 0;
        opacity: 0;
        pointer-events: none;
      }
    }
    @keyframes menuView {
      0% {
        opacity: 0;
        pointer-events: none;
      }
      100% {
        opacity: 1;
        pointer-events: auto;
      }
    }
    @keyframes menuHide {
      0% {
        opacity: 1;
        pointer-events: auto;
      }
      100% {
        opacity: 0;
        pointer-events: none;
      }
    }

    .l-headerNav {
      opacity: 0;
      pointer-events: none;

      @include m.sp {
        padding: 0 0 10rem;
      }
    }

    &.is-view {
      animation: menuOpen 0.4s v.$animeBezier forwards;

      @include m.sp {
        padding: 7rem 0 0;
      }

      .l-headerNav {
        animation: menuView 0.4s v.$animeBezier 0.6s forwards;
        opacity: 0;
        pointer-events: auto;
      }
    }
  }

  &Hamburger {
    width: 48px;
    height: 30px;
    position: absolute;
    top: 50%;
    right: 30px;
    z-index: 2;
    transform: translateY(-50%);

    @include m.pc {
      cursor: pointer;

      &:hover {
        .l-headerHamburger_line {
          &--top {
            background-color: v.$greenLight;
          }

          &--mid {
            background-color: v.$greenLight;
          }

          &--btm {
            background-color: v.$greenLight;
          }
        }
      }
    }

    @include m.sp {
      width: 4.5rem;
      height: 2.8rem;
      right: 1.5rem;
    }

    &.is-active {
      .l-headerHamburger_line {
        &--top {
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &--mid {
          opacity: 0;
        }

        &--btm {
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &_line {
      width: 100%;
      height: 2px;
      background-color: v.$white;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      &--top {
        @extend .l-headerHamburger_line;
        top: 0;
        transition: background-color v.$anime, transform 0.4s v.$animeBezier;
      }

      &--mid {
        @extend .l-headerHamburger_line;
        top: 50%;
        transition: background-color v.$anime, transform 0.4s v.$animeBezier;
      }

      &--btm {
        @extend .l-headerHamburger_line;
        top: 100%;
        transition: background-color v.$anime, transform 0.4s v.$animeBezier;
      }
    }
  }

  &Nav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include m.sp {
      height: 100%;
      display: block;
      overflow-y: scroll;
    }

    &_wrap {
      width: 100%;
      margin: 0 auto;

      @include m.pc {
        max-width: 1040px;
        padding: 0 20px;
      }

      @include m.sp {
        width: 100%;
      }
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      @include m.sp {
        display: block;
        border-top: 1px solid v.$white;
      }

      & + & {
        @include m.pc {
          margin-top: 56px;
        }
      }
    }

    &_box {
      @include m.sp {
        width: 100%;
        overflow: hidden;
      }

      & + & {
        @include m.pc {
          margin: 0 0 0 96px;
        }

        @include m.sp {
          border-top: 1px solid v.$white;
        }
      }
    }

    &_input {
      display: none;

      @include m.sp {
        & + .l-headerNav_heading + .l-headerNav_list {
          max-height: 0;
          opacity: 0;
          padding-top: 0;
          padding-bottom: 0;
          transform: translateY(-50%);
          transition: padding 0.2s ease, max-height 0.2s ease, opacity v.$anime 0.1s, transform v.$anime;

          .l-headerNav_listItem {
            border-width: 0;
            transition: border-width v.$anime;
          }

          .l-headerNav_listInner {
            padding-top: 0;
            padding-bottom: 0;
            transition: padding v.$anime;
          }
        }

        &:checked + .l-headerNav_heading {
          border-color: v.$white;

          .l-headerNav_headingIco {
            &:after {
              transform: translate(-50%, -50%) rotate(0);
            }
          }
        }

        &:checked + .l-headerNav_heading + .l-headerNav_list {
          max-height: 100%;
          padding-top: 1rem;
          padding-bottom: 1rem;
          opacity: 1;
          transform: translateY(0);

          .l-headerNav_listItem {
            border-width: 1px;
          }

          .l-headerNav_listInner {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }
      }
    }

    &_heading {
      width: 100%;
      padding: 0 0 32px;
      display: block;
      font-size: 21px;
      font-weight: 500;
      line-height: 1;
      color: v.$white;

      @include m.sp {
        padding: 2rem 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: v.$green;
        border-bottom: 1px solid;
        position: relative;
        z-index: 1;
        overflow: hidden;
      }

      &Item {
        $this: &;
        display: block;
        @include m.pc {
          transition: color v.$anime;

          &:hover {
            color: v.$greenLight;
          }
        }

        @include m.sp {
          pointer-events: none;
        }
      }

      &Ico {
        @include m.pc {
          display: none;
        }

        @include m.sp {
          width: 1.2rem;
          height: 2px;
          background-color: v.$white;
          position: absolute;
          top: 50%;
          right: 1.5rem;
          transform: translateY(-50%);

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: v.$white;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%) rotate(90deg);
            transition: transform v.$anime;
          }
        }
      }
    }

    &_list {
      width: 100%;
      position: relative;
      z-index: 0;

      @include m.sp {
        padding: 1rem 5rem;
      }

      &Item {
        & + & {
          @include m.pc {
            margin-top: 24px;
          }
        }
      }

      &Inner {
        line-height: 1;
        color: v.$white;

        @include m.pc {
          transition: color 0.6s v.$animeBezier;

          &:hover {
            color: v.$greenLight;
          }
        }

        @include m.sp {
          padding: 2rem 0;
          display: block;
        }
      }
    }

    &_childList {
      @extend .l-headerNav_list;
      padding-left: 1em;

      @include m.sp {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 1rem;
      }

      &Item {
        @extend .l-headerNav_listItem;
        margin-top: 0.5em;
        display: flex;
        align-items: center;

        @include m.sp {
          margin-top: 0;
          margin-bottom: 1em;
          padding-top: 0;
          padding-bottom: 0;
        }

        &:before {
          content: '';
          width: 8px;
          height: 2px;
          margin-right: 0.5em;
          background-color: v.$white;
        }
      }

      &Inner {
        @extend .l-headerNav_listInner;

        @include m.sp {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  &Other {
    width: 100%;
    margin-top: 84px;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      margin-top: 4rem;
      padding: 0 3rem;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &_box {
      @include m.pc {
        max-width: 50%;
      }

      @include m.sp {
        &:first-child {
          order: 1;
        }

        &:last-child {
          margin: 0 0 2rem;
          order: 0;
        }
      }
    }

    &_list {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: flex-end;

      @include m.sp {
        display: block;
      }

      &Item {
        color: v.$white;

        @include m.pc {
          transition: color v.$anime;

          &:hover {
            color: v.$greenLight;
          }
        }

        & + & {
          @include m.pc {
            margin-left: 32px;
          }

          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }

      &Inner {
        line-height: 1;
      }
    }

    &_copy {
      line-height: 1;
      color: v.$white;

      &Inner {
        line-height: 1;
      }
    }
  }
}
