@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-pager {
  width: 100%;
  margin-top: 144px;
  display: flex;
  justify-content: center;

  @include m.tab {
    margin-top: 80px;
  }

  @include m.sp {
    margin-top: 6rem;
  }

  .wp-pagenavi {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .pages {
    display: none;
  }

  .current,
  .page {
    width: 64px;
    height: 64px;
    margin: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: v.$green;
    border: 1px solid v.$green;
    border-radius: 50%;

    @include m.tab {
      width: 48px;
      height: 48px;
      font-size: 16px;
    }

    @include m.sp {
      width: 4rem;
      height: 4rem;
      margin: 0 1rem;
      font-size: 1.4rem;
    }
  }

  .current {
    color: v.$white;
    background-color: v.$green;
  }

  .page {
    @include m.pc {
      transition: color v.$anime, background-color v.$anime;

      &:hover {
        color: v.$white;
        background-color: v.$green;
      }
    }
  }

  .previouspostslink,
  .nextpostslink {
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);

    @include m.pc {
      &:after {
        transition: border-color v.$anime;
      }

      &:hover {
        &:after {
          border-color: v.$greenLight;
        }
      }
    }

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%) rotate(-45deg);

      @include m.sp {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .previouspostslink {
    left: -60px;

    @include m.sp {
      left: -2.5rem;
    }

    &:after {
      border-top: 2px solid v.$green;
      border-left: 2px solid v.$green;
    }
  }

  .nextpostslink {
    right: -60px;

    @include m.sp {
      right: -2.5rem;
    }

    &:after {
      border-right: 2px solid v.$green;
      border-bottom: 2px solid v.$green;
    }
  }
}
