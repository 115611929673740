@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-notfound {
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: left;

  @include m.sp {
    width: 100vw;
    height: 50vh;
  }

  &_heading {
    margin: 0 0 40px;

    @include m.sp {
      margin: 0 0 2rem;
    }
  }

  &_wave {
    top: 75% !important;
    @include m.sp {
      top: 68% !important;
    }
  }
}
