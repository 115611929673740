@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-anime {
  &Heading {
    padding: 4px 12px;
    color: v.$white;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      background-color: v.$greenLight;
      z-index: 2;
      transition: width 0.4s ease-in-out, transform 0.2s ease-in-out;
    }

    &:after {
      background-color: v.$green;
      z-index: 0;
    }

    &.is-animated {
      &:before {
        animation: toRight 0.8s ease-in-out 0.2s forwards;
      }

      &:after {
        animation: toMax 0.6s ease-in-out 0.4s forwards;
      }

      .c-animeHeading {
        &_item {
          opacity: 1;
        }
      }
    }

    @keyframes toRight {
      0% {
        width: 0;
        left: 0;
      }
      65% {
        width: 100%;
        left: 0;
      }
      100% {
        width: 0;
        left: 100%;
      }
    }

    @keyframes toMax {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    &--single {
      @extend .c-animeHeading;

      &:after {
        background-color: v.$white;
      }

      .c-animeHeading {
        &_item {
          color: v.$green;
        }
      }
    }

    &_item {
      opacity: 0;
      transition: opacity 0.4s ease 0.8s;
      position: relative;
      z-index: 1;
    }
  }

  &View {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.8s v.$animeBezier 0.3s, transform 0.8s v.$animeBezier 0.3s;

    &.is-animated {
      opacity: 1;
      transform: translateY(0);
    }

    &_top {
      &--first,
      &--second {
        opacity: 0;
        transform: translateY(50px);
      }

      &--first {
        transition: opacity 0.8s v.$animeBezier 0.3s, transform 0.8s v.$animeBezier 0.3s;

        @include m.sp {
          transition: opacity 0.8s v.$animeBezier 0.6s, transform 0.8s v.$animeBezier 0.6s;
        }
      }

      &--second {
        transition: opacity 0.8s v.$animeBezier 0.6s, transform 0.8s v.$animeBezier 0.6s;

        @include m.sp {
          transition: opacity 0.8s v.$animeBezier 0.3s, transform 0.8s v.$animeBezier 0.3s;
        }
      }

      &.is-animated {
        .c-animeView {
          &_top {
            &--first,
            &--second {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }

    &_series {
      &Inner {
        opacity: 0;
        transform: translateY(50px);

        @for $i from 1 through 5 {
          &:nth-of-type(#{$i}) {
            transition: opacity 0.8s v.$animeBezier (0.1s * $i), transform 0.8s v.$animeBezier (0.1s * $i);
          }
        }
      }

      &.is-animated {
        .c-animeView {
          &_series {
            &Inner {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
