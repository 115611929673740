@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  width: 100%;
  padding: 160px 0;
  position: relative;
  z-index: 1;
  background-color: v.$white;

  @include m.sp {
    height: 100vh;
    padding: 0 0 4rem;
  }

  &_wrap {
    width: 100%;

    @include m.sp {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }

  &_cont {
    width: 100%;
  }

  &_logo {
    width: 100%;
    margin: 0 0 54px;

    @include m.pc {
      max-width: 235px;
    }

    @include m.sp {
      margin: 0 0 2.5rem;
    }
  }

  &_inner {
    width: 100%;
    margin: 0 0 40px;

    @include m.sp {
      display: none;
    }

    &Box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      & + & {
        margin: 40px 0 0;

        @include m.sp {
          margin: 2rem 0 0;
        }
      }
    }
  }

  &Nav {
    & + & {
      margin: 0 0 0 88px;
    }

    &_list {
      width: 100%;

      & + & {
        margin: 12px 0 0;
      }

      &--lead {
        margin: 0 0 16px;
      }
    }

    &_item {
      padding: 4px;
      line-height: 1;
      color: v.$green;
      background-color: v.$white;

      @include m.pc {
        transition: color v.$anime;

        &:hover {
          color: v.$greenLight;
        }
      }
    }

    &_child {
      @extend .l-footerNav;
      margin-bottom: 0.5em;
      padding-left: 1em;

      &List {
        @extend .l-footerNav_list;
        margin-top: 0.5em;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          width: 8px;
          height: 1px;
          margin-right: 0.3em;
          background-color: v.$green;
        }
      }

      &Item {
        @extend .l-footerNav_item;
        font-size: 13px;
        font-weight: 500;

        @include m.tab {
          font-size: 11px;
        }

        @include m.sp {
          font-size: 1.1rem;
        }
      }
    }
  }

  &Other {
    width: 100%;

    &_cont {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      @include m.sp {
        flex-flow: column;
        align-items: flex-start;
      }
    }

    &_box {
      @include m.sp {
        order: 1;
      }
    }

    &_nav {
      width: 100%;
      margin: 0 0 8px;
      display: flex;
      justify-content: flex-start;

      @include m.sp {
        display: none;
      }

      &List {
        & + & {
          margin-left: 32px;

          @include m.sp {
            margin-left: 1.5rem;
          }
        }
      }

      &Item {
        color: v.$green;

        @include m.pc {
          transition: color v.$anime;

          &:hover {
            color: v.$greenLight;
          }
        }
      }
    }

    &_copy {
      color: v.$green;
    }

    &_mark {
      width: 77px;
      margin: 0 0 0 30px;
      position: relative;
      z-index: 1;

      @include m.pc {
        &:after {
          content: '';
          width: 120%;
          height: 120%;
          background-color: v.$white;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translate(-50%, -50%);
        }
      }

      @include m.sp {
        width: 6.5rem;
        margin: 0 0 1rem;
        order: 0;
      }
    }
  }

  &Back {
    width: 85px;
    height: 85px;
    position: absolute;
    right: 14%;
    bottom: 16.5%;
    z-index: 100;

    @include m.sp {
      width: 5.5rem;
      height: 5.5rem;
      right: 2.5rem;
      bottom: 3.8rem;
    }

    &_item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: v.$white;
      border: 1px solid v.$green;
      border-radius: 50%;
      transform-origin: center;

      @include m.pc {
        .l-footerBack_itemInner {
          transition: transform v.$anime;
        }

        &:hover {
          .l-footerBack_itemInner {
            transform: rotate(1260deg);
          }
        }
      }

      &Inner {
        width: 14px;
        display: block;

        @include m.sp {
          width: 1rem;
          transform: rotate(180deg);
        }
      }
    }
  }
}
