@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-link {
  display: inline-flex;
  align-items: center;
  color: v.$green;

  @include m.pc {
    transition: color v.$anime;

    &:hover {
      color: v.$greenLight;

      .c-link {
        &_ico {
          border-color: v.$greenLight;

          &Bg {
            transform: translate(0, 0);
            animation: linkHoverBg 0.4s v.$animeBezier;
          }

          &Arrow {
            animation: linkHoverArrow 0.4s ease forwards;
          }
        }
      }
    }
  }

  &--inversion {
    @extend .c-link;
    color: v.$white;
  }

  &_text {
    padding-right: 20px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.1em;

    @include m.tab {
      font-size: 16px;
    }

    @include m.sp {
      padding-right: 2rem;
      font-size: 1.4rem;
      flex: 1;
    }
  }

  &_ico {
    width: 64px;
    height: 64px;
    display: block;
    background-color: v.$white;
    border: 1px solid v.$green;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include m.pc {
      transition: border-color v.$anime;
    }

    @include m.sp {
      width: 5rem;
      height: 5rem;
    }

    &Bg {
      width: 100%;
      height: 100%;
      background-color: v.$greenLight;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transform: translate(110%, 0);
    }

    @keyframes linkHoverBg {
      0% {
        transform: translate(-110%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    &Arrow {
      width: 10px;
      height: 10px;
      border-right: 2px solid v.$green;
      border-bottom: 2px solid v.$green;
      position: absolute;
      top: 50%;
      left: calc(50% - 1px);
      z-index: 2;
      transform: translate(-50%, -50%) rotate(-45deg);
      // transition: border-color 0.4s v.$animeBezier, opacity 0.4s v.$animeBezier, transform 0.4s v.$animeBezier;
      opacity: 1;
    }

    @keyframes linkHoverArrow {
      0% {
        transform: translate(-50%, -50%) rotate(-45deg);
        opacity: 1;
      }
      33% {
        transform: translate(260%, -50%) rotate(-45deg);
        opacity: 1;
      }
      50% {
        transform: translate(260%, -50%) rotate(-45deg);
        opacity: 0;
      }
      66% {
        transform: translate(-260%, -50%) rotate(-45deg);
        opacity: 0;
        border-color: v.$white;
      }
      100% {
        transform: translate(-50%, -50%) rotate(-45deg);
        opacity: 1;
        border-color: v.$white;
      }
    }
  }
}
