@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-hero {
  width: 100%;
  position: relative;
  z-index: 1;

  &_main {
    width: 100%;
    height: 660px;
    position: relative;
    z-index: 1;

    @include m.sp {
      height: 50vh;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(v.$green, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    img {
      width: 100%;
    }
  }

  &Box {
    width: 100%;
    position: absolute;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);

    &_heading {
      margin: 0 0 16px;

      @include m.sp {
        margin: 0 0 1rem;
      }
    }

    &_lead {
      letter-spacing: 0.2em;
    }
  }
}
