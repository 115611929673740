@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-summary {
  width: 100%;
  padding: 220px 0 120px;

  @include m.tab {
    padding: 160px 0 80px;
  }

  @include m.sp {
    padding: 8rem 0 4rem;
  }

  &Wrap {
    width: 100%;

    @include m.pc {
      max-width: 640px;
    }

    & + & {
      margin-top: 140px;

      @include m.tab {
        margin-top: 100px;
      }

      @include m.sp {
        margin-top: 5rem;
      }
    }

    &--full {
      @extend .c-summaryWrap;

      @include m.pc {
        max-width: 100%;
      }
    }
  }

  &Intro {
    width: 100%;
    margin: 0 0 100px;

    @include m.tab {
      margin: 0 0 60px;
    }

    @include m.sp {
      margin: 0 0 4rem;
    }
  }

  &Cont {
    width: 100%;

    & + & {
      margin-top: 140px;

      @include m.tab {
        margin-top: 100px;
      }

      @include m.sp {
        margin-top: 5rem;
      }
    }

    &_text {
      display: inline-block;
      font-family: v.$zenKaku;

      & + & {
        padding-top: 32px;

        @include m.tab {
          padding-top: 20px;
        }

        @include m.sp {
          padding-top: 2rem;
        }
      }
    }
  }
}
