@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-service {
  &Index {
    width: 100%;
    padding: 248px 0 400px;
    overflow: hidden;

    @include m.tab {
      padding: 180px 0 280px;
    }

    @include m.sp {
      padding: 12rem 0;
    }
  }

  &Media {
    width: 100%;
    margin: 0 0 220px;
    display: flex;
    justify-content: space-between;

    @include m.tab {
      margin: 0 0 180px;
    }

    @include m.sp {
      margin: 0 0 12rem;
      flex-flow: column;
      justify-content: flex-start;
    }

    &--end {
      @extend .p-serviceMedia;
      margin: 0;
      align-items: flex-end;
    }

    &_box {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
        order: 1;
      }
    }

    &_logo {
      width: 50%;
      margin: 0 0 48px;

      @include m.pc {
        max-width: 246px;
      }

      @include m.tab {
        margin: 0 0 28px;
      }

      @include m.sp {
        margin: 0 0 2rem;
      }
    }

    &_inner {
      width: 100%;
    }

    &_lead {
      width: 100%;
      padding: 0 0 20px;
    }

    &_pic {
      width: 41%;
      margin: 0 0 0 92px;

      @include m.pc {
        max-width: 410px;
      }

      @include m.tab {
        margin: 0 0 0 60px;
      }

      @include m.sp {
        width: 80%;
        margin: 0 auto 3rem;
        order: 0;
      }

      &--echo {
        @extend .p-serviceMedia_pic;
        width: 40%;

        @include m.pc {
          max-width: 316px;
        }

        @include m.sp {
          width: 100%;
        }
      }
    }
  }

  &Cont {
    margin: 0 0 220px;

    @include m.tab {
      margin: 0 0 180px;
    }

    @include m.sp {
      margin: 0 0 12rem;
    }
  }
}
