@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-info {
  &List {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include m.sp {
      display: block;
    }

    & + & {
      margin: 72px 0 0;

      @include m.tab {
        margin: 48px 0 0;
      }

      @include m.sp {
        margin: 3rem 0 0;
      }
    }

    &_head {
      width: 160px;
      margin: 0 20px 0 0;

      @include m.sp {
        width: 100%;
        margin: 0 0 0.5rem;
      }
    }

    &_body {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
      }

      &Inner {
        & + & {
          margin-top: 36px;

          @include m.sp {
            margin-top: 1.5rem;
          }
        }
      }

      .c-infoList {
        &_text {
          @include m.sp {
            font-weight: normal !important;
          }
        }
      }
    }

    &_tel {
      font-size: inherit;

      @include m.pc {
        pointer-events: none;
      }

      @include m.sp {
        color: v.$greenLight;
        text-decoration: underline;
      }
    }

    &_mail {
      font-size: inherit;
      color: v.$greenLight;
      text-decoration: underline;
    }

    &_text {
      font-family: v.$zenKaku;
    }
  }

  &Cont {
    width: 100%;

    @include m.pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &_box {
      width: 45%;

      @include m.pc {
        max-width: 450px;
      }

      @include m.sp {
        width: 100%;

        & + & {
          margin-top: 4rem;
        }
      }

      &:nth-child(n + 3) {
        @include m.pc {
          margin-top: 100px;
        }

        @include m.tab {
          margin-top: 60px;
        }
      }

      &--row03 {
        width: 31%;

        @include m.sp {
          width: 100%;

          & + & {
            margin-top: 6rem;
          }
        }

        .c-infoCont {
          &_heading {
            @include m.pc {
              text-align: center;
            }
          }
        }
      }
    }

    &_ico {
      width: 54px;
      height: 56px;
      margin: 0 auto 28px;

      @include m.sp {
        width: 6rem;
        height: 6rem;
        margin: 0 auto 1rem;
      }

      img {
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    &_heading {
      width: 100%;
      margin-bottom: 20px;

      @include m.tab {
        margin-bottom: 12px;
      }

      @include m.sp {
        margin-bottom: 0.5rem;
      }

      &--spacing {
        @extend .c-infoCont_heading;
        letter-spacing: 0.2em;
      }
    }

    &_text {
      font-family: v.$zenKaku;
    }
  }

  &Box {
    width: 100%;
    border: 1px solid v.$green;

    &_intro {
      width: 100%;
      padding: 4px 0;
      text-align: center;
      border-bottom: 1px solid v.$green;
    }

    &_inner {
      width: 100%;
      padding: 8px 12px;
    }
  }

  &Indent {
    width: 100%;

    &_list {
      margin-left: 1.5em;
      list-style: disc;
    }
  }

  &Table {
    width: 100%;
    border: 1px solid v.$green;

    tr {
      &:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }

    th,
    td {
      padding: 12px 16px;
      vertical-align: middle;

      @include m.sp {
        padding: 1.2rem;
      }
    }

    th {
      width: 200px;
      color: v.$white;
      background-color: v.$green;
      border-bottom: 1px solid v.$white;

      @include m.sp {
        width: 35%;
      }
    }

    td {
      border-bottom: 1px solid v.$green;
    }
  }

  &Contact {
    width: 100%;
    display: flex;

    @include m.sp {
      display: block;
    }

    &_box {
      width: 50%;
      display: flex;

      @include m.sp {
        width: 100%;
        display: block;
      }

      &:first-child {
        justify-content: flex-start;
      }

      &:last-child {
        justify-content: flex-end;
      }

      & + & {
        @include m.pc {
          border-left: 1px solid v.$green;
        }

        @include m.sp {
          margin-top: 2rem;
          padding-top: 2rem;
          border-top: 1px solid v.$green;
        }
      }

      &Inner {
        text-align: left;
      }
    }

    &_lead {
      color: v.$green;
    }

    &_tel {
      margin: 0 0 8px;
      display: block;
      font-size: 45px;
      font-weight: 500;
      line-height: 1.2;
      color: v.$green;

      @include m.pc {
        pointer-events: none;
      }

      @include m.tab {
        font-size: 32px;
      }

      @include m.sp {
        font-size: 2.4rem;
        color: v.$greenLight;
        text-decoration: underline;
      }
    }

    &_text {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.4444444444444444;
      color: v.$green;

      @include m.tab {
        font-size: 16px;
      }

      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }
}
