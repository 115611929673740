@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  width: 100%;
  margin: 0 auto;

  @include m.pc {
    max-width: 1040px;
    padding: 0 20px;
  }

  @include m.sp {
    width: 85%;
  }

  &Cont {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 3;

    @include m.pc {
      max-width: 1400px;
    }

    &--right {
      @extend .l-wrapCont;

      display: flex;
      justify-content: flex-end;
    }

    &--left {
      @extend .l-wrapCont;

      display: flex;
      justify-content: flex-start;
    }

    &_box {
      &--full {
        width: 100%;
      }

      &--size01 {
        width: 100%;

        @include m.pc {
          max-width: 980px;
        }
      }

      &--size02 {
        width: 100%;

        @include m.pc {
          max-width: 810px;
        }
      }

      &--size03 {
        width: 100%;

        @include m.pc {
          max-width: 1200px;
        }
      }
    }

    &_inner {
      width: 100%;
      padding: 16% 17%;

      @include m.sp {
        padding: 4rem;
      }
    }
  }
}
