@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-contact {
  padding: 220px 0 0;

  @include m.tab {
    padding: 180px 0 0;
  }

  @include m.sp {
    padding: 10rem 0 0;
  }

  &Entry {
    padding: 120px 0 0;
  
    @include m.tab {
      padding: 80px 0 0;
    }
  
    @include m.sp {
      padding: 4rem 0 0;
    }
  }

  &Thanks {
    padding: 160px 0 0;

    @include m.tab {
      padding: 100px 0 0;
    }

    @include m.sp {
      padding: 6rem 0 0;
    }

    .p-contactBtn {
      padding: 36px 0;

      @include m.sp {
        padding: 3rem 0;
      }
    }
  }

  &Form {
    &_cont {
      width: 100%;
      margin-bottom: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include m.tab {
        margin-bottom: 60px;
      }

      @include m.sp {
        margin-bottom: 4rem;
        display: block;
      }

      &Head {
        width: 200px;
        margin-right: 100px;
        color: v.$green;

        @include m.sp {
          width: 100%;
          margin: 0 0 1rem;
        }
      }

      &Text {
        color: v.$green;

        sup {
          margin-left: 4px;
          color: v.$greenLight;
        }
      }

      &Body {
        position: relative;
        z-index: 1;

        @include m.pc {
          flex: 1;
        }

        @include m.sp {
          width: 100%;
        }

        .error {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 2;
        }
      }
    }

    &_input,
    &_textarea {
      width: 100%;
      padding: 8px 16px;
      font-family: v.$zenKaku;
      font-size: 14px;
      font-weight: 500;
      color: v.$green;
      line-height: 2;
      border: 1px solid v.$green;
      -webkit-appearance: none;

      @include m.tab {
        font-size: 13px;
      }

      @include m.sp {
        font-size: 1.4rem;
      }

      &::-webkit-input-placeholder {
        color: v.$gray;
      }

      &::-moz-placeholder {
        color: v.$gray;
      }

      &:-ms-input-placeholder {
        color: v.$gray;
      }

      &::-ms-input-placeholder {
        color: v.$gray;
      }

      &::placeholder {
        color: v.$gray;
      }
    }

    &_textarea {
      padding: 20px 16px;
      resize: none;
    }

    &_select {
      width: 100%;
      position: relative;
      z-index: 1;

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      &:before {
        width: 100%;
        height: 100%;
        background-color: v.$white;
        top: 0;
        left: 0;
        z-index: -2;
      }

      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 7px 0 7px;
        border-color: v.$green transparent transparent transparent;
        top: 50%;
        right: 16px;
        z-index: -1;
        transform: translateY(-50%);
      }

      select {
        @extend .p-contactForm_input;
      }
    }

    &_radio {
      @include m.sp {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .mwform-radio-field {
        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        color: v.$green;

        @include m.tab {
          font-size: 13px;
        }

        @include m.sp {
          font-size: 1.4rem;
        }

        & + .mwform-radio-field {
          @include m.pc {
            margin-left: 64px !important;
          }

          @include m.sp {
            margin: 1rem 0 0 !important;
          }
        }

        label {
          padding-left: 30px;
          display: inline-block;
          white-space: nowrap;

          input {
            & + span {
              display: inline-block;
              position: relative;
              z-index: 1;

              &:before {
                content: '';
                width: 17px;
                height: 17px;
                border: 1px solid v.$green;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: calc(100% + 13px);
                z-index: 2;
                transform: translateY(-50%);
              }

              &:after {
                content: '';
                width: 9px;
                height: 9px;
                background-color: v.$greenLight;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                right: calc(100% + 17px);
                z-index: 2;
                transform: translateY(-50%);
                opacity: 0;
              }
            }

            &:checked + span {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &_file {
      .mwform-file-delete {
        display: none;
      }

      .error {
        position: static;
      }

      &Inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &Label {
        width: 200px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: v.$green;
        background-color: v.$white;
        border: 1px solid v.$green;
        position: relative;
        z-index: 1;

        @include m.pc {
          cursor: pointer;
          transition: color v.$anime, background-color v.$anime;

          &:hover {
            color: v.$white;
            background-color: v.$green;

            &:after {
              color: v.$green;
            }
          }
        }

        &:after {
          content: "選択されていません";
          position: absolute;
          top: 50%;
          left: calc(100% + 24px);
          z-index: 2;
          transform: translateY(-50%);
          white-space: nowrap;
          pointer-events: none;
        }

        &.is-changed {
          &:after {
            content: "";
          }
        }
      }

      &Input {
        display: none !important;
      }

      &Name {
        margin-left: 24px;
        display: inline-block;
      }

      .mw-wp-form_file {
        a {
          display: block;
          text-indent: -9999px;
          font-size: 0;
        
          @include m.pc {
            transition: opacity v.$anime;

            &:hover {
              opacity: .7;
            }
          }

          &:after {
            content: "選択したファイルはこちらから確認できます";
            display: block;
            font-size: 14px;
            line-height: 1;
            text-decoration: underline;
            color: v.$green;
            opacity: 1 !important;
            text-indent: 0;
          }
        }
      }

    }

    &_privacy {
      width: 100%;
      display: flex;
      justify-content: center;

      &Label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;

        .error {
          position: absolute;
          top: 100%;
          left: 0;
        }

        label {
          input {
            display: none;

            & + span {
              width: 18px;
              height: 18px;
              margin-right: 6px;
              display: block;
              border: 1px solid v.$green;
              position: relative;
              z-index: 1;
              text-indent: -9999px;

              &:after {
                content: '';
                width: 10px;
                height: 6px;
                border-left: 2px solid v.$green;
                border-bottom: 2px solid v.$green;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 2;
                transform: translate(-50%, -50%) rotate(-45deg);
                opacity: 0;
              }
            }

            &:checked + span {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }

      &Link {
        color: v.$green;
        text-decoration: underline;

        @include m.pc {
          transition: color v.$anime;

          &:hover {
            color: v.$greenLight;
          }
        }
      }
    }
  }

  &Btn {
    width: 100%;
    margin-top: 68px;
    display: flex;
    justify-content: center;
    background-color: v.$green;

    &_cont {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &List {
        width: 100%;

        &:first-child {
          display: none;
        }

        &:last-child {
          label {
            input {
              margin-right: 20px;
            }

            .p-contactBtn_contText {
              margin-right: 20px;
            }
          }
        }

        label {
          width: 100%;
          padding: 36px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 1;

          @include m.pc {
            cursor: pointer;

            .p-contactBtn_contText {
              &:after {
                transition: color v.$anime;
              }
            }

            &:hover {
              .p-contactBtn_contText {
                &:after {
                  color: v.$greenLight;
                }
              }
            }
          }

          @include m.sp {
            padding: 3rem 0;
          }

          input {
            width: 100%;
            height: 100%;
            display: block;
            font-size: 16px;
            line-height: 2;
            text-indent: -9999px;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            transform: translateY(-50%);

            @include m.tab {
              font-size: 14px;
            }

            @include m.sp {
              font-size: 1.4rem;
            }
          }

          .p-contactBtn_contText {
            &:after {
              content: '確認する';
              width: 100%;
              font-size: 16px;
              line-height: 2;
              color: v.$white;

              @include m.tab {
                font-size: 14px;
              }

              @include m.sp {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}

/* 確認画面用 */
.mw_wp_form_confirm {
  .p-contact {
    & * {
      font-family: v.$zenKaku;
    }

    &Confirmation {
      display: none;
    }

    &Form {
      &_cont {
        &Head {
          sup {
            display: none;
          }
        }

        &Body {
          @include m.sp {
            font-size: 1.4rem;
          }
        }
      }

      &_select {
        &:before,
        &:after {
          display: none;
        }
      }

      &_privacy {
        display: none;
      }
    }

    &Btn {
      &_cont {
        &List {
          width: 50%;

          &:first-child {
            padding-right: 40px;
            display: block;

            @include m.sp {
              padding-right: 2rem;
            }

            label {
              justify-content: flex-start;
              flex-direction: row-reverse;

              input {
                padding-left: 94px;
                right: 0;
                left: auto;

                @include m.sp {
                  padding-left: 68px;
                }
              }

              .p-contactBtn_contText {
                margin-left: 20px;

                &:after {
                  content: '戻る';
                }
              }

              .p-contactBtn_contIco {
                transform: rotate(180deg);
              }
            }
          }

          &:last-child {
            padding-left: 40px;

            @include m.sp {
              padding-left: 2rem;
            }

            label {
              justify-content: flex-start;

              .p-contactBtn_contText {
                &:after {
                  content: '送信する';
                }
              }
            }
          }
        }
      }
    }
  }
}
/* end 確認画面用 */
