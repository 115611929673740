@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-wave {
  $this: &;
  width: 67%;
  // height: 48vw;
  background-color: v.$greenLight;
  transform-style: preserve-3d;
  position: fixed;
  top: 88%;
  left: 94%;
  z-index: -1;
  // transform: translate(-50%, -50%) rotate(-90deg) scale(1.2);
  transform-origin: center;
  -webkit-animation: rotation 15s ease-in-out infinite;
  animation: rotation 15s ease-in-out infinite;
  will-change: transform;

  @include m.pc {
    // max-width: 668px;
    // max-height: 672px;
  }

  @include m.sp {
    order: 0;
    top: 42%;
    left: 63%;
    -webkit-animation: rotationSp 15s ease-in-out infinite;
    animation: rotationSp 15s ease-in-out infinite;
  }

  &--toggle {
    @extend .c-wave;
    opacity: 0;
    transition: opacity ease 0.3s;

    &.is-view {
      opacity: 1;
    }
  }

  &--entry {
    @extend #{$this};
    @include m.pc {
      top: 68% !important;
    }
  }

  &_item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &Wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: top;
      perspective: 10000px;
    }

    &Plane {
      // font-size: 60px;
      font-size: 2.2vw;
      position: relative;
      top: 30%;
      left: -34%;
      // transform: rotateX(60deg) rotateZ(220deg);
      transform: rotateX(63deg) rotateZ(94deg) skew(8deg, 14deg);
      transform-style: preserve-3d;
      perspective: 10000px;
      transform-origin: right;
      will-change: transform;

      @include m.sp {
        font-size: 4.2vw;
      }
    }

    &Row {
      width: 16em;
      height: 0.4em;
      position: absolute;
      transform-style: preserve-3d;
      perspective: 10000px;
      will-change: transform, top;

      @for $i from 0 to 44 {
        &:nth-child(#{$i + 1}) {
          top: v.$cube-s * $i * 1.5;
          -webkit-animation: anim 2.5s ease-in-out infinite $i * 0.1s;
          animation: anim 2.5s ease-in-out infinite $i * 0.1s;
        }
      }
    }

    &Dot {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      transition: transform 0.1s ease-in-out;
      width: 0.4em;
      height: 0.4em;
      transform-style: preserve-3d;
      perspective: 10000px;
      will-change: transform, left;

      &:before {
        background: v.$greenLight;
        // border-radius: 10px;
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        // transform: rotateX(-300deg);
        transform: rotateX(40deg) rotateY(40deg) skew(-4deg, 2deg);
        // width: 6px;
        // height: 6px;
        width: 0.25vw;
        height: 0.25vw;
        min-width: 4px;
        min-height: 4px;

        @include m.sp {
          min-width: 2px;
          min-height: 2px;
        }
      }

      @for $k from 0 to 42 {
        &:nth-child(#{$k + 1}) {
          -webkit-animation: anim 2s ease-in-out infinite $k * 0.1s;
          animation: anim 2s ease-in-out infinite $k * 0.1s;
        }
      }
      @for $j from 0 to 42 {
        &:nth-child(#{$j + 1}) {
          left: v.$cube-s * $j * 2.8;
        }
      }
    }
  }
}

@-webkit-keyframes anim {
  0% {
    transform: translateY(0) translateZ(0);
  }
  50% {
    // transform: translateZ(calc(v.$cube-s/2));
    transform: translateY(calc(v.$cube-s/2.5)) translateZ(calc(v.$cube-s/2.5));
  }
  100% {
    transform: translateY(0) translateZ(0);
  }
}

@keyframes anim {
  0% {
    transform: translateY(0) translateZ(0);
  }
  50% {
    // transform: translateZ(calc(v.$cube-s/2));
    transform: translateY(calc(v.$cube-s/2.5)) translateZ(calc(v.$cube-s/2.5));
  }
  100% {
    transform: translateY(0) translateZ(0);
  }
}

@-webkit-keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
  13% {
    transform: translate(-50%, calc(-50% + 8px)) rotate(-90deg) scale(1.5);
  }
  25% {
    transform: translate(-50%, calc(-50% + 3px)) rotate(-93deg) scale(1.45);
  }
  38% {
    transform: translate(-50%, calc(-50% + 2px)) rotate(-96deg) scale(1.5);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-94deg) scale(1.5);
  }
  66% {
    transform: translate(-50%, calc(-50% - 2px)) rotate(-90deg) scale(1.5);
  }
  75% {
    transform: translate(-50%, calc(-50% - 3px)) rotate(-90deg) scale(1.45);
  }
  88% {
    transform: translate(-50%, calc(-50% - 8px)) rotate(-90deg) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
  13% {
    transform: translate(-50%, calc(-50% + 8px)) rotate(-90deg) scale(1.5);
  }
  25% {
    transform: translate(-50%, calc(-50% + 3px)) rotate(-93deg) scale(1.45);
  }
  38% {
    transform: translate(-50%, calc(-50% + 2px)) rotate(-96deg) scale(1.5);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-94deg) scale(1.5);
  }
  66% {
    transform: translate(-50%, calc(-50% - 2px)) rotate(-90deg) scale(1.5);
  }
  75% {
    transform: translate(-50%, calc(-50% - 3px)) rotate(-90deg) scale(1.45);
  }
  88% {
    transform: translate(-50%, calc(-50% - 8px)) rotate(-90deg) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
}

@-webkit-keyframes rotationSp {
  0% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
  13% {
    transform: translate(-50%, calc(-50% + 8px)) rotate(-90deg) scale(1.5);
  }
  25% {
    transform: translate(-50%, calc(-50% + 3px)) rotate(-93deg) scale(1.45);
  }
  38% {
    transform: translate(-50%, calc(-50% + 2px)) rotate(-96deg) scale(1.5);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-94deg) scale(1.5);
  }
  66% {
    transform: translate(-50%, calc(-50% - 2px)) rotate(-90deg) scale(1.5);
  }
  75% {
    transform: translate(-50%, calc(-50% - 3px)) rotate(-90deg) scale(1.45);
  }
  88% {
    transform: translate(-50%, calc(-50% - 8px)) rotate(-90deg) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
}

@keyframes rotationSp {
  0% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
  13% {
    transform: translate(-50%, calc(-50% + 8px)) rotate(-90deg) scale(1.5);
  }
  25% {
    transform: translate(-50%, calc(-50% + 3px)) rotate(-93deg) scale(1.45);
  }
  38% {
    transform: translate(-50%, calc(-50% + 2px)) rotate(-96deg) scale(1.5);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-94deg) scale(1.5);
  }
  66% {
    transform: translate(-50%, calc(-50% - 2px)) rotate(-90deg) scale(1.5);
  }
  75% {
    transform: translate(-50%, calc(-50% - 3px)) rotate(-90deg) scale(1.45);
  }
  88% {
    transform: translate(-50%, calc(-50% - 8px)) rotate(-90deg) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.5);
  }
}
