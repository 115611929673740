@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-data {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include m.sp {
    display: block;
  }

  &_list {
    width: 48%;
    padding: 7.2% 7.2% 9.2%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: v.$white;
    border: 1px solid v.$green;

    @include m.pc {
      max-width: 480px;
    }

    @include m.sp {
      width: 100%;
      padding: 3rem 3rem 5.5rem;
    }

    &:nth-child(n + 3) {
      @include m.pc {
        margin-top: 40px;
      }
    }

    & + & {
      @include m.sp {
        margin-top: 4rem;
      }
    }
  }

  &_box {
    width: 100%;

    &:nth-of-type(2) {
      margin-top: 48px;

      @include m.sp {
        margin-top: 2rem;
      }
    }

    &:nth-of-type(3) {
      margin-top: 64px;

      @include m.sp {
        margin-top: 3rem;
      }
    }
  }

  &_intro {
    font-family: v.$zenKaku;
  }

  &_border {
    width: 100%;
    display: flex;

    &List {
      width: 50%;
      display: flex;
      padding-bottom: 8px;

      &:first-child {
        justify-content: flex-start;
        border-right: 1px solid v.$green;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  &_text {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 100px;
    font-weight: 500;
    line-height: 0.85;

    @include m.tab {
      font-size: 80px;
    }

    @include m.sp {
      font-size: 6.5rem;
    }

    &Sub {
      display: block;
      font-size: 35px;
      font-weight: 500;
      line-height: 1;
      white-space: nowrap;

      @include m.tab {
        font-size: 28px;
      }

      @include m.sp {
        font-size: 2rem;
      }
    }
  }

  &_pic {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &_graph {
    width: 100%;
    display: flex;
    border: 1px solid v.$green;

    &--gender {
      @extend .c-data_graph;
      height: 90px;

      .c-data_graphList {
        &:first-child {
          width: 54%;
        }

        &:last-child {
          width: 46%;
        }
      }
    }

    &--recruit {
      @extend .c-data_graph;
      height: 90px;

      .c-data_graphList {
        &:first-child {
          width: 28%;
        }

        &:last-child {
          width: 72%;
        }
      }
    }

    &List {
      display: flex;
      justify-content: center;
      align-items: center;
      color: v.$green;

      &.is-focus {
        color: v.$white;
        background-color: v.$green;
      }
    }

    &Text {
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;

      @include m.tab {
        font-size: 18px;
      }

      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }

  &_ico {
    width: 88px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include m.sp {
      width: 6rem;
      height: 6rem;
    }

    &--link {
      @extend .c-data_ico;
      width: 190px;
      height: 50px;
      padding-top: 20px;

      @include m.sp {
        width: 14rem;
        height: 6rem;
      }
    }

    &--human {
      @extend .c-data_ico;
      width: 34px;
      height: 62px;

      @include m.sp {
        width: 3.5rem;
        height: 4rem;
      }
    }

    img {
      object-fit: contain;

      @include m.sp {
        height: 100%;
      }
    }
  }

  &_note {
    width: 100%;
    position: absolute;
    bottom: 28px;
    left: 0;
    z-index: 2;

    @include m.tab {
      bottom: 20px;
    }

    @include m.sp {
      bottom: 1rem;
    }

    &Text {
      width: 100%;
      padding: 0 2.8%;
      font-family: v.$zenKaku;
      text-align: right;

      @include m.sp {
        padding: 0 3rem;
      }
    }
  }
}
