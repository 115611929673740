@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-recruit {
  &Cont {
    width: 100%;
    padding: 220px 0;

    @include m.tab {
      padding: 180px 0;
    }

    @include m.sp {
      padding: 10rem 0;
    }

    &--recruitment {
      width: 100%;
      padding: 220px 0 110px;

      @include m.tab {
        padding: 180px 0 80px;
      }

      @include m.sp {
        padding: 10rem 0 6rem;
      }
    }

    &--btm {
      width: 100%;
      padding: 0 0 220px;

      @include m.tab {
        padding: 0 0 180px;
      }

      @include m.sp {
        padding: 0 0 10rem;
      }
    }
  }

  &Data {
    width: 100%;

    & + & {
      margin-top: 220px;

      @include m.tab {
        margin-top: 180px;
      }

      @include m.sp {
        margin-top: 10rem;
      }
    }

    &_intro {
      width: 100%;
      margin: 0 0 100px;

      @include m.tab {
        margin: 0 0 80px;
      }

      @include m.sp {
        margin: 0 0 4rem;
      }
    }
  }

  &Info {
    width: 100%;
    border: 1px solid v.$green;

    & + & {
      margin-top: 70px;

      @include m.tab {
        margin-top: 60px;
      }

      @include m.sp {
        margin-top: 4rem;
      }
    }

    &_cont {
      width: 100%;
      padding: 0 40px;

      @include m.tab {
        padding: 0 24px;
      }

      @include m.sp {
        padding: 0 2rem;
      }
    }

    &_box {
      opacity: 0;
      height: 0;
      padding: 0;
      transition: opacity v.$anime, height v.$anime, padding-bottom v.$anime;

      &.is-open {
        margin-top: 72px;
        opacity: 1;
        height: auto;
        padding: 0 0 68px;

        @include m.tab {
          margin-top: 60px;
          padding: 0 0 48px;
        }

        @include m.sp {
          margin-top: 3rem;
          padding: 0 0 4rem;
        }
      }

      strong {
        font-weight: bold !important;
      }

      a {
        text-decoration: underline;
        word-break: break-all;

        @include m.pc {
          transition: opacity v.$anime;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    &_intro {
      width: 100%;
      padding: 68px 0;
      position: relative;
      z-index: 1;

      @include m.tab {
        padding: 48px 0;
      }

      @include m.sp {
        padding: 2rem 3rem 2rem 0;
      }

      &.is-open {
        .p-recruitInfo {
          &_ico {
            &:before {
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      &Name {
        width: 100%;
        padding-bottom: 12px;
        letter-spacing: 0.2em;

        @include m.sp {
          width: 100%;
          padding-bottom: 1rem;
        }
      }

      &Cat {
        padding-right: 32px;
        display: flex;

        &List {
          width: 120px;
          height: 25px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: v.$green;
          border: 1px solid v.$green;

          @include m.sp {
            width: auto;
            height: auto;
            padding: 2px 8px;
          }
        }
      }
    }

    &_ico {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 2;
      transform: translateY(-50%);

      @include m.pc {
        cursor: pointer;
      }

      @include m.sp {
        width: 2rem;
        height: 2rem;
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 4px;
        background-color: v.$green;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;

        @include m.sp {
          height: 2px;
        }
      }

      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
        transition: transform 0.1s ease;
      }

      &:after {
        transform: translate(-50%, -50%);
      }
    }

    &_btn {
      width: 100%;
      padding: 36px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: v.$green;

      @include m.tab {
        padding: 28px 0;
      }

      @include m.sp {
        padding: 2rem 0;
      }
    }
  }
}
