@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-index {
  &Main {
    display: block;
    position: relative;
    z-index: 1;
    // scroll-snap-type: y mandatory;

    &:after {
      content: '';
      width: 100vw;
      height: 100vh;
      background-color: v.$green;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -2;
      transition: background-color v.$anime;
    }

    &.is-animated {
      &:after {
        background-color: v.$white;
      }

      .p-indexMain_sec {
        // height: auto;
        // scroll-snap-type: none;
        // pointer-events: none;
        // overflow: visible;
        // scroll-snap-type: y proximity;
      }

      .p-indexHero {
        // scroll-snap-align: none;
      }
    }

    &_sec {
      height: 100vh;
      overflow-y: scroll;
      pointer-events: auto;
      scrollbar-width: none;
      scroll-snap-type: y mandatory;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }

      &.is-scrolled {
        height: 100vh;
        overflow: hidden;
        scroll-snap-type: y proximity;
      }
    }
  }

  &Hero {
    width: 100vw;
    height: 100vh;
    will-change: top;
    scroll-snap-align: end;
    scroll-snap-stop: always;
    // overflow: hidden;
    // scroll-snap-align: start;

    &_wrap {
      width: 72%;
      height: 100%;
      margin: 0 auto;

      @include m.sp {
        width: 92%;
      }
    }

    &_cont {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;

      @include m.sp {
        padding: 0;
        flex-flow: column;
        justify-content: center;
      }

      &--first {
        @extend .p-indexHero_cont;
        transition: opacity 0.1s ease;

        &.is-animated {
          opacity: 0;
        }
      }
    }

    &_box {
      width: 100%;
      position: relative;
      z-index: 2;

      @include m.sp {
        order: 1;
      }
    }

    &_heading {
      padding: 0 0 48px;
      font-size: 5vw;
      font-weight: 300;
      line-height: 1.2941176470588236;
      letter-spacing: 0.085em;
      color: v.$white;
      font-weight: bold;
      font-family: v.$zenKaku;
      text-align: center;

      @include m.tab {
        font-size: 56px;
      }

      @include m.sp {
        padding: 0 0 2rem;
        font-size: 3rem;
      }
    }

    &_text {
      font-size: 1.7vw;
      line-height: 1.4583333333333333;
      letter-spacing: 0.2em;
      color: v.$white;

      @include m.tab {
        font-size: 20px;
      }

      @include m.sp {
        font-size: 1.8rem;
      }

      a {
        display: inline-block;
        margin-top: 4px;
        text-decoration: underline !important;
      }
    }

    &_scroll {
      position: fixed;
      right: 14%;
      bottom: 20%;
      z-index: -1;
      opacity: 1;
      pointer-events: none;
      transition: opacity v.$anime;

      @include m.sp {
        bottom: 5rem;
      }

      &.is-hide {
        opacity: 0;
        pointer-events: auto;
      }
    }

    &_lead {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      font-size: 24px;
      font-weight: 500;
      line-height: 2;
      color: v.$green;

      @include m.tab {
        font-size: 20px;
      }

      @include m.sp {
        font-size: 1.8rem;
      }
    }

    &_sub {
      margin: 48px 0;
      display: inline-block;
      font-size: 80px;
      font-weight: 300;
      line-height: 1;

      @include m.tab {
        font-size: 60px;
      }

      @include m.sp {
        margin: 3rem 0;
        font-size: 5.25rem;
      }
    }

    &_copy {
      @extend .p-indexHero_lead;
      display: inline-block;
      color: v.$white;
    }

    &_link {
      margin: 48px 0 0;
      opacity: 0;
      transform: translateX(-100px);
      transition: opacity 0.8s ease 0.6s, transform 0.8s v.$animeBezier 0.6s;

      @include m.sp {
        margin: 3rem 0 6rem;
      }

      &.is-animated {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &Info {
    width: 100%;

    &_wrap {
      width: 100%;
      margin: 0 0 60px;

      @include m.sp {
        margin: 0 0 4rem;
      }
    }

    &_slide {
      overflow: visible;

      &:before {
        content: '';
        width: 100%;
        height: calc(100% + 40px);
        background-color: v.$grayPale;
        position: absolute;
        top: -20px;
        right: 100%;
        z-index: 2;
      }

      &Item {
        width: 100%;

        @include m.pc {
          max-width: 280px;
        }

        @include m.sp {
          width: 65%;
        }
      }

      &Link {
        width: 100%;
        display: block;

        @include m.pc {
          transition: opacity v.$anime;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      &Button {
        top: -15%;
        transform: translate(-50%, -50%);

        @include m.sp {
          top: -2.5rem;
        }

        &:before {
          content: '';
          width: 28px;
          height: 28px;

          @include m.sp {
            width: 2rem;
            height: 2rem;
          }
        }

        &:after {
          display: none;
        }

        @include m.pc {
          transition: border-color v.$anime;

          &:hover {
            border-color: v.$greenLight;
          }
        }

        &--next {
          @extend .p-indexInfo_slideButton;
          left: 98%;

          &:before {
            border-right: 2px solid v.$green;
            border-bottom: 2px solid v.$green;
            transform: rotate(-45deg);
          }
        }

        &--prev {
          @extend .p-indexInfo_slideButton;
          left: 88%;

          &:before {
            border-top: 2px solid v.$green;
            border-left: 2px solid v.$green;
            transform: rotate(-45deg);
          }
        }
      }

      &Bar {
        margin: 44px 0 0;
        position: static;

        .swiper-pagination-progressbar-fill {
          background-color: v.$green;
        }
      }
    }

    &_btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &Service {
    width: 100%;
    margin: 0 0 200px;

    @include m.sp {
      margin: 0 0 2rem;
    }

    &_intro {
      width: 100%;
      margin: 0 0 160px;
    }

    &_Wrap {
      width: 100%;
      overflow: hidden;
    }
  }

  &Company {
    width: 100%;
    padding: 180px 0 315px;
    background-color: v.$grayPale;

    @include m.sp {
      padding: 9rem 0 15rem;
    }

    &_intro {
      width: 100%;
      margin: 0 0 272px;
      overflow: hidden;

      @include m.sp {
        margin: 0 0 29rem;
      }
    }

    &_cont {
      width: 100%;
      position: relative;
      z-index: 1;

      &Top {
        width: 100%;
        position: absolute;
        top: 12%;
        left: 0;
        z-index: 3;
        transform: translateY(-50%);

        @include m.sp {
          top: -10%;
        }
      }

      &Pic {
        width: 100%;
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          background-color: rgba(v.$black, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }

      &Btm {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 3;
        transform: translateY(-50%);
        overflow: hidden;

        @include m.tab {
          top: calc(100% + 40px);
        }
      }
    }

    &_btn {
      width: 100%;
      margin: 7.2% 0 0;
      display: flex;
      justify-content: flex-end;

      @include m.tab {
        margin: 6% 0 0;
      }

      @include m.sp {
        margin: 3rem 0 0;
      }

      &Item {
        color: v.$white;
      }
    }

    &_link {
      width: 100%;
      padding: 10% 0 10% 11.2%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: v.$white;
      position: relative;
      z-index: 1;

      @include m.tab {
        padding: 6% 0 6% 7%;
      }

      &:after {
        content: '';
        width: 100vw;
        height: 100%;
        background-color: v.$green;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      @include m.pc {
        .p-indexCompany_link {
          &Ico {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transition: transform 0.8s v.$animeBezier;
          }
        }

        &:hover {
          .p-indexCompany_link {
            &Ico {
              transform: translate(20px, -50%);
            }
          }
        }
      }

      @include m.sp {
        padding: 4rem 0 4rem 4rem;
      }

      &Intro {
        width: 100%;
      }

      &Heading {
        padding: 0 0 16px;
        font-size: 80px;
        font-weight: 300;
        line-height: 1;
        color: v.$white;

        @include m.sp {
          padding: 0 0 2rem;
          font-size: 4rem;
        }
      }

      &Text {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4583333333333333;
        letter-spacing: 0.2em;

        @include m.tab {
          font-size: 20px;
        }

        @include m.sp {
          font-size: 1.5rem;
        }
      }

      &Ico {
        width: 17%;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 2;
        transform: translateY(-50%);

        @include m.sp {
          width: 8.7rem;
        }
      }
    }
  }
}
