@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-stalker {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  // mix-blend-mode: multiply;
  z-index: 9999;

  @include m.sp {
    display: none;
    pointer-events: none;
  }
}
