@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-media {
  width: 100%;

  &_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include m.sp {
      flex-flow: column;
    }

    & + & {
      margin: 240px 0 0;

      @include m.sp {
        margin: 8rem 0 0;
      }
    }

    &--alignStart {
      align-items: flex-start;
    }

    &--alignCenter {
      align-items: center;
    }

    &--alignEnd {
      align-items: flex-end;
    }

    &--imgR {
      @extend .c-media_cont;
      .c-media_box {
        margin: 0 80px 0 0;
        order: 0;

        @include m.sp {
          margin: -3rem 0 3rem;
          order: 1;
        }
      }

      .c-media_pic {
        margin: 10% 8% 0 0;
        order: 1;

        @include m.sp {
          margin: 0 auto;
          order: 0;
        }

        &Bg {
          top: 75%;
          left: 110%;

          @include m.sp {
            left: 68%;
          }

          &--type02 {
            top: 35%;
            left: 110%;

            @include m.sp {
              top: 30%;
            }
          }
        }
      }

      &.c-media_cont--alignStart {
        .c-media_box {
          @include m.sp {
            margin: 3rem 0;
          }
        }

        .c-media_pic {
          margin-top: 0;

          @include m.sp {
            width: 100%;
          }

          &Front {
            @include m.sp {
              width: 60%;
              margin: 0 auto;
            }
          }
        }
      }

      &.c-media_cont--alignEnd {
        .c-media_box {
          @include m.sp {
            margin: 3rem 0;
          }
        }

        .c-media_pic {
          @include m.sp {
            width: 100%;
          }

          &Front {
            @include m.sp {
              width: 60%;
              margin: 0 auto;
            }
          }
        }
      }
    }

    &--imgL {
      @extend .c-media_cont;
      .c-media_box {
        margin: -1% 0 0;
        padding: 0 0 0 80px;
        order: 1;

        @include m.sp {
          margin: 3rem 0;
          padding: 0;
        }
      }

      .c-media_pic {
        order: 0;

        @include m.sp {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        &Front {
          @include m.sp {
            width: 60%;
          }
        }

        &Bg {
          top: 81%;
          left: 11%;

          @include m.sp {
            top: 74%;
            left: 39%;
          }
        }
      }
    }
  }

  &_box {
    $this: &;
    margin: 0 8% 0 0;
    position: relative;
    z-index: 2;

    @include m.pc {
      flex: 1;
    }

    @include m.sp {
      position: relative;
      z-index: 2;
    }

    &--full {
      @extend #{$this};
      margin: 0;
    }

    &Bg {
      width: 123%;
      position: absolute;
      top: 75%;
      left: 110%;
      z-index: -1;
      transform: translate(-50%, -50%);
      will-change: transform;

      @include m.sp {
      }

      @include m.pc {
        max-width: 494px;
      }

      @include m.sp {
        width: 25rem;
        top: 30%;
        left: 68%;
      }

      &--type02 {
        @extend .c-media_picBg;
        left: 113%;
      }
    }
  }

  &_intro {
    width: 100%;
    margin: 0 0 24px;

    @include m.sp {
      margin: 0 0 2rem;
    }
  }

  &_heading {
    width: 100%;
    font-size: 64px;
    font-weight: 300;
    line-height: 1.203125;
    color: v.$green;

    @include m.tab {
      font-size: 48px;
    }

    @include m.sp {
      width: 100%;
      font-size: 4rem;
    }
  }

  &_num {
    font-size: 200px;
    font-weight: 300;
    line-height: 1.2;

    @include m.tab {
      font-size: 160px;
    }

    @include m.sp {
      display: inline-block;
      font-size: 8rem;
      background-color: v.$white;
    }
  }

  &_lead {
    width: 100%;
    padding: 40px 0 0;
    font-family: v.$zenKaku;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.05em;
    color: v.$green;

    @include m.tab {
      font-size: 20px;
    }

    @include m.sp {
      padding: 2rem 0 0;
      font-size: 1.8rem;
    }

    &--inner {
      @extend .c-media_lead;
      padding: 24px 0;

      @include m.tab {
        padding: 16px 0;
      }

      @include m.sp {
        padding: 2rem 0;
      }
    }
  }

  &_text {
    line-height: 2;
    font-family: v.$zenKaku;
  }

  &_btn {
    width: 100%;
    margin: 52px 0 0;

    @include m.sp {
      margin: 2.5rem 0 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  &_info {
    width: 100%;
    margin: 20px 0 0;

    @include m.sp {
      margin: 1rem 0 0;
    }
  }

  &_pic {
    width: 40%;
    position: relative;
    z-index: 1;

    @include m.pc {
      max-width: 410px;
    }

    @include m.sp {
      width: 60%;
    }

    &Front {
      width: 100%;
      position: relative;
      z-index: 2;
    }

    &Bg {
      width: 123%;
      position: absolute;
      z-index: 1;
      transform: translate(-50%, -50%);
      will-change: transform;

      @include m.pc {
        max-width: 494px;
      }

      @include m.sp {
        width: 25rem;
        top: 30%;
      }

      &--type02 {
        @extend .c-media_picBg;
        left: 113%;
      }
    }
  }

  &Simple {
    width: 100%;

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        flex-flow: column;
      }

      & + & {
        margin-top: 100px;

        @include m.tab {
          margin-top: 60px;
        }

        @include m.sp {
          margin-top: 5rem;
        }
      }

      &--imgR {
        @extend .c-mediaSimple_cont;

        .c-mediaSimple {
          &_box {
            order: 0;

            @include m.sp {
              order: 1;
            }
          }

          &_pic {
            margin-left: 60px;
            order: 1;

            @include m.sp {
              margin: 0 0 2rem;
              order: 0;
            }
          }
        }
      }

      &--imgL {
        @extend .c-mediaSimple_cont;

        .c-mediaSimple {
          &_pic {
            margin-right: 60px;
            order: 0;

            @include m.sp {
              margin: 0 0 2rem;
            }
          }

          &_box {
            order: 1;
          }
        }
      }
    }

    &_box {
      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
      }
    }

    &_heading {
      width: 100%;
      margin: 0 0 12px;

      @include m.sp {
        margin: 0 0 0.5rem;
      }
    }

    &_btn {
      margin: 48px 0 0;

      @include m.tab {
        margin: 32px 0 0;
      }

      @include m.sp {
        width: 100%;
        margin: 2rem 0 0;
        display: flex;
        justify-content: flex-end;
      }
    }

    &_pic {
      width: 50%;

      @include m.pc {
        max-width: 500px;
      }

      @include m.sp {
        width: 100%;
      }

      iframe {
        width: 100%;

        @include m.sp {
          height: 25rem;
        }
      }
    }
  }
}
