@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-scroll {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &.is-animated {
    .c-scroll {
      &_heading {
        color: v.$green;
      }

      &_item {
        background-color: v.$green;
      }
    }
  }

  &_heading {
    width: 7px;
    margin: 0 -2px 12px 0;
    line-height: 1;
    color: v.$white;
    transition: color v.$anime;

    &Item {
      display: block;
      transform: rotate(90deg);
    }
  }

  &_item {
    width: 1px;
    height: 80px;
    display: block;
    background: linear-gradient(to bottom, rgba(v.$white, 1) 50%, rgba(v.$white, 0) 50%);
    background-position: 0 -80px;
    background-size: 100% 200%;
    animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    transition: background-color v.$anime;

    @include m.sp {
      height: 4rem;
      background-position: 0 -4rem;
      animation: scrolldownSp 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    }

    @keyframes scrolldown {
      0% {
        background-position: 0 -80px;
      }
      75% {
        background-position: 0 0;
      }
      100% {
        background-position: 0 80px;
      }
    }

    @keyframes scrolldownSp {
      0% {
        background-position: 0 -4rem;
      }
      85% {
        background-position: 0 0;
      }
      100% {
        background-position: 0 4rem;
      }
    }
  }
}
