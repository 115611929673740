@use 'global/mixin' as m;
@use 'global/variable' as v;

.u-text {
  font-size: 16px;
  line-height: 2;

  @include m.tab {
    font-size: 14px;
  }

  @include m.sp {
    font-size: 1.4rem;
  }

  &--light {
    @extend .u-text;
    font-weight: 300;
  }

  &--medium {
    @extend .u-text;
    font-weight: 500;
  }

  &--semiBold {
    @extend .u-text;
    font-weight: 600;
  }

  &--bold {
    @extend .u-text;
    font-weight: 700;
  }

  &--l {
    font-size: 64px;
    line-height: 1.203125;

    @include m.tab {
      font-size: 40px;
    }

    @include m.sp {
      font-size: 2.4rem;
    }

    &--light {
      @extend .u-text--l;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--l;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--l;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--l;
      font-weight: 700;
    }
  }

  &--xl {
    font-size: 80px;
    line-height: 1.2;

    @include m.tab {
      font-size: 60px;
    }

    @include m.sp {
      font-size: 2.6rem;
    }

    &--light {
      @extend .u-text--xl;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--xl;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--xl;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--xl;
      font-weight: 700;
    }
  }

  &--2xl {
    font-size: 85px;
    line-height: 1.2941176470588236;

    @include m.tab {
      font-size: 64px;
    }

    @include m.sp {
      font-size: 2.7rem;
    }

    &--light {
      @extend .u-text--2xl;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--2xl;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--2xl;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--2xl;
      font-weight: 700;
    }
  }

  &--sm {
    font-size: 20px;
    line-height: 2;

    @include m.tab {
      font-size: 16px;
    }

    @include m.sp {
      font-size: 1.5rem;
    }

    &--light {
      @extend .u-text--sm;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--sm;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--sm;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--sm;
      font-weight: 700;
    }
  }

  &--m {
    font-size: 24px;
    line-height: 2;

    @include m.tab {
      font-size: 18px;
    }

    @include m.sp {
      font-size: 1.6rem;
    }

    &--light {
      @extend .u-text--m;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--m;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--m;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--m;
      font-weight: 700;
    }
  }

  &--xm {
    font-size: 26px;
    line-height: 1.8461538461538463;

    @include m.tab {
      font-size: 20px;
    }

    @include m.sp {
      font-size: 1.8rem;
    }

    &--light {
      @extend .u-text--xm;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--xm;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--xm;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--xm;
      font-weight: 700;
    }
  }

  &--s {
    font-size: 14px;
    line-height: 2;

    @include m.tab {
      font-size: 12px;
    }

    @include m.sp {
      font-size: 1.2rem;
    }

    &--light {
      @extend .u-text--s;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--s;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--s;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--s;
      font-weight: 700;
    }
  }

  &--xs {
    font-size: 12px;

    @include m.tab {
      font-size: 10px;
    }

    @include m.sp {
      font-size: 1rem;
    }

    &--light {
      @extend .u-text--xs;
      font-weight: 300;
    }

    &--medium {
      @extend .u-text--xs;
      font-weight: 500;
    }

    &--semiBold {
      @extend .u-text--xs;
      font-weight: 600;
    }

    &--bold {
      @extend .u-text--xs;
      font-weight: 700;
    }
  }
}
