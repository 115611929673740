@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-about {
  &Profile {
    width: 100%;
    margin: 0 auto;
    padding: 220px 0;
    position: relative;
    z-index: 1;

    @include m.pc {
      max-width: 1400px;
    }

    @include m.tab {
      padding: 160px 0;
    }

    @include m.sp {
      padding: 6rem 0;
      overflow: hidden;
    }

    &_bg {
      width: 100%;
      max-height: 1420px;
      height: 200vh;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;

      @include m.sp {
        max-height: 100%;
      }

      &--contain {
        @extend .p-aboutProfile_bg;
        max-height: 100%;
        height: 100%;
      }

      &Inner {
        width: 100%;
        height: 100%;

        @include m.pc {
          max-width: 1040px;
        }
      }
    }
  }

  &Philosophy {
    &_text {
      margin-top: 16px;
      display: block;

      @include m.sp {
        margin-top: 1rem;
      }
    }
  }

  &Intro {
    padding: 100px 0 0;

    @include m.tab {
      padding: 60px 0 0;
    }

    @include m.sp {
      padding: 4rem 0 0;
    }
  }

  &Value {
    width: 100%;

    &_cont {
      & + & {
        margin-top: 60px;

        @include m.sp {
          margin-top: 3rem;
        }
      }
    }

    &_info {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include m.sp {
        display: block;
      }

      & + & {
        margin-top: 32px;
      }
    }

    &_intro {
      display: flex;

      @include m.sp {
        width: 100%;
        margin: 0 0 0.5rem;
        align-items: flex-end;
      }
    }

    &_initials {
      width: 32px;
      margin-right: 40px;
      font-size: 42px;
      font-weight: 300;
      text-align: center;

      @include m.tab {
        font-size: 28px;
      }

      @include m.sp {
        margin-right: 2rem;
        font-size: 3.2rem;
        line-height: 1.4;
      }

      &--full {
        @extend .p-aboutValue_initials;
        width: 196px;
        text-align: left;
      }
    }

    &_name {
      width: 164px;
      font-family: v.$zenKaku;
    }

    &_dot {
      margin: 0 32px 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include m.sp {
        display: none;
      }

      &Item {
        width: 4px;
        height: 4px;
        background-color: v.$white;
        border-radius: 50%;

        & + & {
          margin-left: 2px;
        }
      }
    }

    &_box {
      display: flex;
      align-items: center;
      text-align: left;

      @include m.pc {
        flex: 1;
      }

      @include m.sp {
        width: 100%;
        padding-left: 52px;
        display: block;
      }

      &--last {
        @extend .p-aboutValue_box;

        @include m.sp {
          padding: 0;
        }
      }
    }

    &_cross {
      width: 50px;
      height: 50px;
      margin-left: 31%;
      position: relative;
      z-index: 1;

      @include m.sp {
        width: 4.5rem;
        height: 4.5rem;
        margin: 0 auto;
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: v.$white;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  &Access {
    width: 100%;
    padding: 160px 0;
    position: relative;
    z-index: 3;

    @include m.tab {
      padding: 120px 0;
    }

    @include m.sp {
      padding: 6rem 0;
    }
  }

  &History {
    width: 100%;
    padding: 200px 0;

    @include m.tab {
      padding: 120px 0;
    }

    @include m.sp {
      padding: 6rem 0;
    }

    &_info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      & + & {
        dt,
        dd {
          padding-top: 52px;

          @include m.tab {
            padding-top: 55px;
          }

          @include m.sp {
            padding-top: 2.3rem;
          }
        }
      }

      &:first-child {
        .p-aboutHistory {
          &_info {
            &Ico {
              top: 10px;
            }
          }
        }
      }

      &:last-child {
        z-index: 1;
        .p-aboutHistory {
          &_info {
            &Head {
              &:after {
                top: auto;
                bottom: 10px;
                @include m.tab {
                  top: 1.9vw;
                }
                @include m.sp {
                  top: 2.9rem;
                  bottom: auto;
                  height: 2rem;
                  transform: rotate(180deg);
                  transform-origin: top;
                }
              }
            }
          }
        }
      }

      &Head {
        width: 120px;
        margin-right: 20px;
        position: relative;
        z-index: 1;

        @include m.sp {
          width: 30%;
          margin-right: 1rem;
        }

        &:after {
          content: '';
          width: 1px;
          height: 100%;
          background-color: v.$green;
          position: absolute;
          top: 10px;
          right: 5px;
          z-index: -1;
          @include m.tab {
            top: 1.9vw;
          }
        }
      }

      &Text {
        height: 100%;
      }

      &Ico {
        width: 12px;
        height: 12px;
        background-color: v.$white;
        border: 1px solid v.$green;
        border-radius: 10px;
        position: absolute;
        top: 60px;
        right: 0;
        z-index: 1;

        @include m.sp {
          top: 2.9rem;
          transform: none;
        }
      }

      &Body {
        flex: 1;
      }
    }
  }
}
