@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-privacy {
  &Wrap {
    width: 100%;
    padding: 120px 0 100px;

    @include m.tab {
      padding: 100px 0 80px;
    }

    @include m.sp {
      padding: 6rem 0 4rem;
    }
  }

  &Cont {
    &--right {
      width: 100%;
      margin: 24px 0 0;
      text-align: right;

      @include m.sp {
        margin: 2rem 0 0;
      }
    }
  }
}
